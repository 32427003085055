<template>
  <div class="box m_tb_20" v-if="courseListData.courseList.length">
    <!--    内容-->
    <el-row class="flex_row_wrap">
      <div class="course-box bg_fff br_8 of_h flex_row m_b_20 pointer"
           v-for="(item, index) of courseListData.courseList"
           :key="index"
           @click="showDetailsBtn_click(item)">
        <div style="width: 100%;">
          <img :src="item.coverUrl" width="100%" height="160" alt="课程"/>
          <div class="p_in_10">
            <div class="fs_14 m_b_10" v-if="item.courseStartTime">
              <span> {{ item.courseStartTime }}</span>
              <el-tag class="m_lr_10" size="small" effect="plain" :type="stateColorJSON[item.stateVal]">
                {{ stateJSON[item.stateVal] }}
              </el-tag>
            </div>
            <div class="fs_16 m_b_10 text_hidden flex_row"
                 style="height:25px;line-height:25px;justify-content: space-between;">
              {{ item.name }}
            </div>
            <div class="fs_14 c_666 m_b_10 flex_row" style="line-height: 24px;">
              <span>{{ item.isTelecast ? item.remarks1 : item.teacherName }}</span>
            </div>
            <span v-show="item.isTelecast" class="flex_row fs_14 m_tb_10" style="height: 20px;line-height: 21px">
            <img width="20px" :src="playImg" alt="播放量">
            <span class="m_lr_10">
              {{ item.browseNumber > 10000 ? (item.browseNumber / 10000).toFixed(1) + ' 万' : item.browseNumber }}
            </span>
          </span>
            <div class="flex_row" v-if="item.courseStartTime">
              <el-button class="btn"
                         size="mini"
                         plain
                         type="info"
                         v-if="item.isSubscribe==1"
                         @click.stop="registrationDetails(item.id)">
                报名详情
              </el-button>
              <el-button class="btn"
                         size="mini"
                         plain
                         type="warning"
                         v-show="item.stateVal == 0||item.stateVal == 5"
                         @click.stop="operationBtn_click({operation:'submit', id:item.id})">
                提交审核
              </el-button>
              <el-button class="btn"
                         size="mini"
                         plain
                         type="success"
                         v-show="item.stateVal == 1"
                         v-if="item.isLubo==1"
                         @click.stop.native="startLive_click(item)">
                开播
              </el-button>
              <el-button class="btn"
                         size="mini"
                         plain
                         type="primary"
                         v-show="item.stateVal == 0 || item.stateVal == 5"
                         @click.stop="operationBtn_click({operation:'edit', id:item.id})">
                编辑
              </el-button>
            </div>
            <el-button style="position: absolute;right:10px;"
                       type="primary"
                       plain
                       v-else
                       @click.stop="playBtn_click(item.id)"
                       size="mini">播放
            </el-button>
          </div>
        </div>
      </div>
    </el-row>

    <!--  报名详情  -->
    <el-dialog :visible.sync="showRegistrationDetails">
      <el-row class="m_b_20">
        <span class="fs_16 fw_bold">报名详情</span>
        <el-table
            :data="nameList"
            style="width: 100%">
          <el-table-column
              prop="name"
              label="姓名"
          >
          </el-table-column>
          <el-table-column
              prop="time"
              label="报名时间"
          >
          </el-table-column>
          <el-table-column
              prop="deptName"
              label="机构">
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-pagination
            small
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            v-on:current-change="currentChange_click"
            hide-on-single-page>
        </el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {getRoomId} from '@/api/live'
import {sessionSet} from "@/utils/local";
import defaultImg from '@/assets/image/default.png'
import {getCoursePeople} from "@/api/mine/teacher";
import playImg from '@/assets/image/play.png'

export default {
  name: 'CourseList',
  props: {
    courseListData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => {
    return {
      /*
      * stateVal
      *   0 未通过
      *   1 未开始
      *   2 直播中
      *   3 已结束
      *   4 审核中
      *   5 待审核
      * */
      stateJSON: {
        0: '未通过',
        1: '未开始',
        2: '直播中',
        3: '已结束',
        4: '审核中',
        5: '待审核'
      },
      stateColorJSON: {
        0: 'warning',
        1: 'info',
        2: 'success',
        3: 'danger',
        4: '',
        5: ''
      },
      defaultImg,
      showRegistrationDetails: false,
      nameList: [],
      pagingParams: {
        telecastId: '',
        current: 1,
        size: 10
      },
      total: 0,
      playImg,
    }

  },
  methods: {
    showDetailsBtn_click(data) {
      this.$emit('showDetail', data)
    },
    // 点击操作
    operationBtn_click(data) {
      this.$emit('clickOperation', data)
    },
    playBtn_click(courseId) {
      this.$router.push({path: '/courseDetail', query: {courseId}})
    },
    // 报名详情
    registrationDetails(id) {// 获取报名详情
      if (id) this.pagingParams.telecastId = id
      getCoursePeople(this.pagingParams).then(res => {
        this.total = res.data.total
        this.nameList = res.data.records
        this.showRegistrationDetails = true
      })
    },
    // 报名人数分页
    currentChange_click(current) {
      // console.log('当前第', current, '页')
      this.pagingParams.current = current
      this.registrationDetails()
    },
    // 开始直播
    startLive_click(course) {
      if (course.isSubscribe == 1) {
        if (course.subNumber < course.minimum) return this.$message.warning('报名人数小于最小报名人数，无法开课！')
      }
      sessionSet('details', course)
      let courseStartTime = course.courseStartTime
      if (new Date(courseStartTime).getTime() - Date.now() > 30 * 60 * 1000) {
        this.$confirm(`该课程开课时间为${course.courseStartTime}，现在时间过早，是否确定开启直播？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.startCourse(course)
        }).catch(() => {
          this.$message.warning('取消开课')
        })
      } else {
        this.startCourse(course)
      }


    },
    startCourse(course) {
      let params = {
        avatarUrl: course.coverUrl,
        createTime: course.createTime,
        createUserId: course.createUserId,
        telecastId: course.id,
        title: course.name,
        state: 0
      }
      getRoomId(JSON.parse(JSON.stringify(params))).then(res => {
        sessionSet('liveCourseRoom', res.data)
        sessionSet('roomInfo', {roomId: res.data.remarks1, roomName: course.name})
        this.$router.push({path: '/pusher'})
      })
    }
  }
}
</script>
<style scoped>
.box {
  width: 1200px;
}

.course-box {
  position: relative;
  transition: all .5s;
}

.course-box:hover {
  box-shadow: 0 0 10px 2px #e0e0e0;
  transition: all .5s;
}

.course-box:nth-child(4n) {
  margin-right: 0;
}

.btn {
  margin: 0 10px 0 0;
}
</style>
