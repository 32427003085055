<template>
  <div class="user_info p_in_20" v-loading="loading">
    <el-row>
      <el-col class="float_r" :span="2" style="position: absolute;top: -50px;right: 0;">
        <el-button type="success" @click="isAdd = true;dialogFormVisible = true;lock=false"
                   style="border-radius:25px;background-color: #409EFF;border: none;">+ 新增课程
        </el-button>
      </el-col>
    </el-row>
    <div style="font-size: 14px;padding-bottom:15px;cursor: pointer;">
      <span @click="isZhibo=true" :style="isZhibo==true?'color:#3888F7':''">直播</span>&nbsp;&nbsp;
      <span :style="isZhibo==false?'color:#3888F7':''" @click="isZhibo=false">录播</span>
    </div>
    <CourseList :courseListData="notStartedCourseList"
                v-on:showDetail="showCourseDetails"
                v-on:clickOperation="operationBtn_click" v-show="isZhibo==true"/>
    <!--  分页  -->
    <el-pagination
        background
        class="m_b_20"
        layout="prev, pager, next"
        :total="courseTotal"
        v-show="courseTotal&&isZhibo==true"
        :page-size="4"
        v-on:current-change="courseCurrent_click"
        hide-on-single-page
    >
    </el-pagination>

    <!--    <CourseList :courseListData="hasEndedCourseList"
                    v-on:showDetail="showCourseDetails"
                    v-on:clickOperation="operationBtn_click"/>-->
    <div v-show="hasEndedCourseList.courseList.length!==0&&isZhibo==false">
      <!-- <div class="title-box p_tb_10" style="position: relative;height:51px;">
        <div style="position: absolute;width:5px;height:17px;background-color:#3888F7;border-radius: 2px;top: 50%;transform: translateY(-12px);"></div>
        <span class="fs_16 fw_bold m_lr_10">录播</span>
      </div> -->
      <div class="flex_row_wrap m_b_20">
        <div class="course-box bg_fff br_8 of_h m_b_20"
             v-for="(item, index) of hasEndedCourseList.courseList"
             :key="index">
          <div class="pointer" @click="playBtn_click(item.id)">
            <img :src="item.coverUrl?item.coverUrl:defaultImg"
                 style="object-fit: cover;"
                 width="100%"
                 height="160"
                 alt="课程"/>
            <div class="p_in_10">
              <div class="fs_16 m_b_10 text_hidden" style="height:2.2rem;">
                {{ item.name }}
              </div>
              <div class="fs_14 c_666 flex_row" style="line-height: 2rem;">
                <span>{{ item.remarks1 }}</span>
                <el-tag size="small" type="danger" class="live_tag" v-if="item.stateVal">
                  {{ stateJSON[item.stateVal] }}
                </el-tag>
                <span class="live_tag flex_row" v-else>
                <img class="m_lr_10" width="20px" :src="playImg" alt="播放量">
                <span>
                  {{ item.browseNumber > 10000 ? (item.browseNumber / 10000).toFixed(1) + ' 万' : item.browseNumber }}
                </span>
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  分页  -->
    <el-pagination
        background
        layout="prev, pager, next"
        :total="luboTotal"
        v-show="luboTotal&&isZhibo==false"
        :page-size="8"
        :current-page="luboParams.current"
        v-on:current-change="luboCurrent_click"
        hide-on-single-page
    >
    </el-pagination>
    <el-empty description="暂无数据"
              v-show="!notStartedCourseList.courseList.length&&!hasEndedCourseList.courseList.length"></el-empty>

    <!--    新增课程-->
    <el-dialog title="新增课程"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               :before-close="cancelBtn_click"
               width="120rem">
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="课程名称" label-width="12rem" prop="name">
              <el-input v-model="addForm.name" autocomplete="off" placeholder="课程名称" style="width:25rem;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开放范围" prop="beType" label-width="12rem">
              <el-select v-model="addForm.beType" placeholder="请选择" style="width:25rem;">
                <el-option
                    label="区域"
                    :value="2">
                </el-option>
                <el-option
                    label="校本"
                    :value="1">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开课对象" prop="beTeacher" label-width="12rem" v-if="addForm.isSubscribeBoolean">
              <el-select v-model="addForm.beTeacher" placeholder="请选择" style="width:25rem;">
                <el-option
                    label="全部"
                    value="0">
                </el-option>
                <el-option
                    label="教师"
                    value="1">
                </el-option>
                <el-option
                    label="学生"
                    value="2">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="是否需要报名" label-width="12rem" prop="isSubscribeBoolean">
              <el-switch v-model="addForm.isSubscribeBoolean"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否录播" label-width="12rem" prop="isLuboBoolean">
              <el-switch v-model="addForm.isLuboBoolean"></el-switch>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="视频上传" label-width="12rem" v-if="addForm.isLuboBoolean" prop="remarks1">
              <div class="mater-upload-container">
                <Simple ref="upload" :headers="header" :before-upload="beforeUploads" :accept="accepts"
                        :upload-arguments="uploadArgumentsObj" :limit="limit" :on-exceed="fileLimitFn"
                        :base-url="baseUrl"
                        :chunk-size="chunkSize" @success="success" @clearFile="clearFile">
                </Simple>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="开课时间" label-width="12rem" prop="courseStartTime">
              <el-date-picker
                  v-model="addForm.courseStartTime"
                  type="datetime"
                  :picker-options="expireTimeOption"
                  :default-value="this.addForm.courseStartTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束时间" label-width="12rem" prop="courseEndTime">
              <el-date-picker
                  v-model="addForm.courseEndTime"
                  type="datetime"
                  :picker-options="expireTimeOption"
                  :default-value="this.addForm.courseEndTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报名截止" label-width="12rem" prop="endTime" v-if="addForm.isSubscribeBoolean">
              <el-date-picker
                  v-model="addForm.endTime"
                  type="datetime"
                  :picker-options="expireTimeOption"
                  :default-value="this.addForm.endTime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="人数上限" label-width="12rem" prop="maximum" v-if="addForm.isSubscribeBoolean">
              <el-input-number v-model="addForm.maximum"
                               controls-position="right"

                               :max="1000">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="人数下限" label-width="12rem" prop="minimum" v-if="addForm.isSubscribeBoolean">
              <el-input-number v-model="addForm.minimum"
                               controls-position="right"
                               :min="1"
                               :max="1000">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目录分类" prop="classifyId" label-width="12rem">
              <el-select v-model="addForm.classifyId" placeholder="请选择" @change="changeClassify">
                <el-option
                    v-for="item in classifyList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属目录" prop="contentsId" label-width="12rem" v-if="!addForm.isSubscribeBoolean">
              <el-cascader :options="catalogueList"
                           clearable
                           :disabled="catalogueList.length===0"
                           v-model="addForm.contentsId"
                           :show-all-levels="false"
                           :props="{ label:'name',value:'id',expandTrigger:'hover',checkStrictly: true}"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="课程封面" prop="coverUrl" label-width="12rem">
              <el-upload
                  class="avatar-uploader"
                  action="/api/blade-resource/oss/endpoint/put-file"
                  list-type="picture-card"
                  :show-file-list="false"
                  :on-change="fileChange"
                  :headers="headers"
                  :on-success="uploadSuccess"
                  :before-upload="beforeUpload"
              >
                <div style="position: relative;" @mouseenter="overCoverImg" @mouseleave="outCoverImg">
                  <el-image v-if="imageUrl"
                            fit="cover"
                            :src="imageUrl"
                            class="avatar"
                            alt/>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div ref="deleteCover" style="position:absolute;top:0;background-color:rgba(0,0,0,0.3);display:none;">
                    <i class="el-icon-delete avatar-uploader-icon" style="color:#fff;" @click.stop="handleRemove"></i>
                  </div>
                </div>
              </el-upload>
              <div class="fs_12 c_999">图片大小不超过10Mb</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="默认封面" prop="" label-width="12rem">
              <img :src="defaultImg" width="150px" alt="">
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属目录" prop="contentsId" label-width="12rem" v-if="addForm.isSubscribeBoolean">
              <el-cascader :options="catalogueList"
                           clearable
                           :disabled="catalogueList.length===0"
                           v-model="addForm.contentsId"
                           :show-all-levels="false"
                           :props="{ label:'name',value:'id',expandTrigger:'hover',checkStrictly: true}"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="课程介绍" label-width="12rem" prop="details">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 200px; overflow-y: hidden;"
                  v-model="addForm.details"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelBtn_click">取 消</el-button>
        <el-button type="primary" @click="submitBtn_click">提 交</el-button>
      </div>
    </el-dialog>

    <!--    查看课程详情-->
    <el-dialog title="课程详情"
               :visible.sync="showDetails"
               width="120rem"
    >
      <el-row>
        <el-col :span="8">
          <img style="display: block;margin:auto;"
               :src="courseDetails.coverUrl?courseDetails.coverUrl:defaultImg"
               :alt="courseDetails.name"
               height="150px"/>
        </el-col>
        <el-col :span="15">
          <el-row class="m_b_10">
            <span class="m_r_30 fs_24 fw_bold">
              {{ courseDetails.name }}
            </span>
          </el-row>
          <el-row class="m_b_10" v-if="courseDetails.courseStartTime">
            <span class="m_r_30">
              {{ courseDetails.courseStartTime }} 至 {{ courseDetails.courseEndTime.slice(-8) }}
            </span>
            <el-tag size="small" effect="plain" :type="stateColorJSON[courseDetails.stateVal]">
              {{ stateJSON[courseDetails.stateVal] }}
            </el-tag>
          </el-row>
          <el-row class="m_b_10">
            {{ courseDetails.isTelecast ? courseDetails.remarks1 : courseDetails.teacherName }}
          </el-row>
          <el-row class="m_b_10" v-show="courseDetails.isSubscribe!=0">
            <span>{{ courseDetails.isTelecast ? '观看次数：' : '报名人数' }}</span>
            {{ courseDetails.isTelecast ? courseDetails.browseNumber : courseDetails.subNumber }}
          </el-row>
          <el-row class="m_b_10" v-if="courseDetails.remarks&&courseDetails.state == 2" style="color:#f56c6c;">
            <span>审核未通过说明：</span>{{ courseDetails.remarks }}
          </el-row>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row class="course_detail">
        <div v-html="courseDetails.isTelecast?courseDetails.introduce:courseDetails.details"></div>
      </el-row>
    </el-dialog>

  </div>
</template>
<script>
import Simple from '@/components/simple-upload/simple'
import url from "@/api/api";
import {addCourse, getCourseList, getResourcesByUserIdPage, updateCourse} from '@/api/mine/teacher'
import {sessionGet} from "@/utils/local";
import CourseList from '@/components/mine/teacherCourseList'
import defaultImg from "@/assets/image/default.png";
import playImg from '@/assets/image/play.png'

/*富文本编辑器*/
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {Message} from "element-ui";
import {getResourcesContents} from "@/api/index";
import {getResourceClassify} from "../../../api/resourceCenter";

export default {
  name: 'teacherMainCourse',
  components: {
    CourseList,
    Editor,
    Toolbar,
    Simple
  },
  data: () => {
    // 课程时间范围
    let courseStartTime, courseEndTime, endTime, maximum, minimum
    let checkCourseStartTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error('课程开始时间不能为空'))
      }
      courseStartTime = new Date(value).getTime()

      if (courseEndTime && courseStartTime > courseEndTime) {
        callback(new Error('课程开始时间不能大于课程结束时间'))
      }

      if (endTime && courseStartTime < endTime) {
        callback(new Error('课程开始时间不能小于报名截止时间'))
      }
      callback()
    }

    let checkCourseEndTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error('课程结束时间不能为空'))
      }

      courseEndTime = new Date(value).getTime()

      if (courseStartTime && courseStartTime > courseEndTime) {
        callback(new Error('课程结束时间不能小于课程开始时间'))
      }
      callback()
    }

    let checkEndTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error('报名截止时间不能为空'))
      }

      endTime = new Date(value).getTime()

      if (courseStartTime && courseStartTime < endTime) {
        callback(new Error('报名截止时间不能大于课程开始时间'))
      }
      callback()
    }

    let numSizeMax = (rule, value, callback) => {
      maximum = value
      if (minimum > maximum) {
        callback(new Error('报名人数上限必须大于报名人数下限'))
      }
      callback()
    }
    //
    let numSizeMin = (rule, value, callback) => {
      minimum = value
      if (maximum < minimum) {
        callback(new Error('报名人数下限必须小于报名人数上限'))
      }
      callback()
    }

    return {
      isZhibo: true,//直播录播切换
      // 课程状态
      /*
      * stateVal
      *   0 未通过
      *   1 未开始
      *   2 直播中
      *   3 已结束
      *   4 审核中
      *   5 待审核
      * */
      stateJSON: {
        0: '未通过',
        1: '未开始',
        2: '直播中',
        3: '已结束',
        4: '审核中',
        5: '待审核'
      },
      stateColorJSON: {
        0: 'danger',
        1: 'info',
        2: 'success',
        3: 'warning',
        4: '',
        5: 'info'
      },
      // 课程详情弹窗
      showDetails: false,
      // 新增课程弹窗
      dialogFormVisible: false,
      // 资源目录分类
      classifyList: [],
      // 资源目录分类
      catalogueList: [],
      // 新增表单数据
      addForm: {
        // 课程封面图
        // coverUrl: 'http://139.155.82.57:9000/prod/upload/20220630/17664457e127299735d5f0c7835d403d.png',
        coverUrl: 'https://admin.sanguyun.xyz:9000/prod/upload/20221012/635d912b6bb557d9ea20d8a0cd2aa96d.png',
        // 学校id
        deptId: '',
        // 课程名称
        name: '',
        // 教师名称
        teacherName: '',
        // 课程类型
        series: 2,
        // 课程标签
        type: '科技',
        // 课程开始时间
        courseStartTime: '',
        // 课程结束事件
        courseEndTime: '',
        // 最小报名人数
        minimum: 1,
        // 最大报名人数
        maximum: 10,
        // 报名截止时间
        endTime: '',
        // 课程简介
        details: '',
        // 课程区域
        beType: 2,
        // 开课对象
        beTeacher: "0",
        classifyId: '',
        contentsId: '',
        isSubscribeBoolean: false,//是否需要报名
        isSubscribe: '',
        isLuboBoolean: false,//是否是录播
        isLubo: '',
        remarks1: '',//视频地址
      },
      // 判断是新增还是修改
      isAdd: true,
      // 新增课程表单验证
      rules: {
        name: [
          {required: true, message: '请输入课程名称', trigger: 'blur'},
        ],
        series: [
          {required: true, message: '请选择课程类型', trigger: 'blur'},
        ],
        courseStartTime: [
          {required: true, validator: checkCourseStartTime, trigger: 'blur'},
        ],
        courseEndTime: [
          {required: true, validator: checkCourseEndTime, trigger: 'blur'},
        ],
        endTime: [
          {required: true, validator: checkEndTime, trigger: 'blur'},
        ],
        maximum: [
          {required: true, validator: numSizeMax, trigger: 'blur'},
        ],
        minimum: [
          {required: true, validator: numSizeMin, trigger: 'blur'},
        ],
        details: [
          {required: true, message: '请输入课程介绍', trigger: 'blur'},
        ],
        classifyId: [
          {required: true, message: '请选择目录分类', trigger: 'blur'},
        ],
        contentsId: [
          {required: true, message: '请选择所属目录', trigger: 'blur'},
        ],
        isSubscribeBoolean: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        isLuboBoolean: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        remarks1: [
          {required: true, message: '请上传视频', trigger: 'blur'},
        ]
      },
      // 课程封面
      imageUrl: '',
      // 所有课程列表
      courseList: [],
      // 课程详情
      courseDetails: {},
      // 未开始课程
      notStartedCourseList: {
        title: '',
        courseList: []
      },
      // 已结束课程
      hasEndedCourseList: {
        title: '',
        courseList: []
      },
      // 报名情况
      nameList: [],
      // 文件上传请求头
      headers: {
        "Authorization": "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
        "Tenant-Id": "501654",
      },
      // 富文本编辑器
      editor: null,
      html: '',
      toolbarConfig: {
        toolbarKeys: [
          /*'fontSize',
          'fontFamily',
          'bold',
          'underline',
          'italic',
          {
            key: 'group-more-style', // 必填，要以 group 开头
            title: '更多样式', // 必填
            iconSvg: '<svg>....</svg>', // 可选
            menuKeys: ["through", "code", "clearStyle"] // 下级菜单 key ，必填
          },
          // 分割线
          '|',
          'color',
          'bgColor',
          // 分割线
          '|',*/
          'uploadImage',
          // 分割线
          '|',
          'bulletedList',
          'numberedList',
          // 分割线
          '|',
          'insertTable',
          {
            key: 'group-table-style', // 必填，要以 group 开头
            title: '操作表格', // 必填
            iconSvg: '<svg>....</svg>', // 可选
            menuKeys: ['deleteTable',
              'insertTableRow',
              'deleteTableRow',
              'insertTableCol',
              'deleteTableCol',] // 下级菜单 key ，必填
          },
        ]
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          'uploadImage': {
            // 上传图片的配置
            // 地址
            server: '/api/blade-resource/oss/endpoint/putFiles',
            fieldName: 'file',

            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 10 * 1024 * 1024, // 1M

            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,

            // 选择文件时的类型限制，默认为 ['image/!*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/!*'],

            // 超时时间，默认为 10 秒
            // timeout: 5 * 1000, // 5 秒
            headers: {
              "Authorization": "Basic c2FiZXI6c2FiZXJfc2VjcmV0",
              "Tenant-Id": "501654",
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              Message.warning('图片大小不能超过10Mb')
              console.log(`${file.name} 上传出错`, err, res)
            },
          },
          'insertImage': {
            onInsertedImage(imageNode) {
              if (imageNode == null) return

              const {src, alt, url, href} = imageNode
              console.log('inserted image', src, alt, url, href)
            },
          }
        }
      },
      mode: 'default', // or 'simple'
      // 报名人数分页
      total: 0,
      pagingParams: {
        telecastId: '',
        current: 1,
        size: 10
      },
      // 课程分页
      courseParams: {
        createUserId: '',
        current: 1,
        size: 4
      },
      courseTotal: 0,
      // 课程分页
      luboParams: {
        createUserId: '',
        current: 1,
        size: 8
      },
      luboTotal: 0,
      lock: false,
      // 加载
      loading: false,

      //  日期选择范围
      expireTimeOption: {
        disabledDate(date) {
          return (
              date.getTime() < Date.now() - 3600 * 1000 * 24
          )
        }
      },
      // 默认封面图
      defaultImg,
      playImg,
      /* 上传插件*/
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      accepts: 'video/mp4',
      acceptsObj: {
        video: ['video/mp4'],
        image: [
          'image/png',
          'image/gif',
          'image/jpeg',
          'image/jpg',
          'image/bmp',
          '.'
        ], // 火狐的accept只支持【.png,.jpg】这种形式，为了兼容，使用 .
        audio: ['audio/mp3', 'audio/mpeg'],
        ppt: [
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          '.ppt',
          '.pptx'
        ],
        excel: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ],
        zip: ['application/zip', 'application/x-zip-compressed']
      },
      acceptDesc: {
        video: 'mp4',
        image: 'png,gif,jpeg,jpg,bmp',
        audio: 'mp3',
        ppt: 'ppt',
        excel: 'xls,xlsx',
        zip: 'zip'
      },
      // 临时自测使用
      uploadArguments: {
        type: 'zip'
      },
      limit: 1,
      chunkSize: 10 * 1024 * 1024,
      share: 1, // 是否共享 0私有  1共享
    }
  },
  computed: {
    header() {
      return {
        Authorization: 'token'
      };
    },
    baseUrl() {
      return url.baseUrl;
    },
    uploadType() {
      return this.uploadArguments.type;
    },
    uploadArgumentsObj() {
      return {
        ...this.uploadArguments,
        share: this.share
      };
    }
  },
  mounted() {
    let date = new Date()
    let token = sessionGet('tk')
    this.userInfo = sessionGet('userInfo')
    this.headers["Blade-Auth"] = token.token_type + ' ' + token.access_token
    this.editorConfig.MENU_CONF.uploadImage.headers['Blade-Auth'] = token.token_type + ' ' + token.access_token
    this.getCourseList()

    this.addForm.courseStartTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1} 14:00:00`
    this.addForm.courseEndTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1} 16:00:00`
    this.addForm.endTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1} 12:00:00`

    this.getResourcesClassify()

    if (this.userInfo.role_name === 'teacher') {
      this.addForm.beType = 1
    }
  },
  methods: {
    /* 文件上传 */
    beforeUploads(file) {
      this.$refs.upload.clearFiles();
      console.log('beforeAvatarUpload -> file', file);
      // if (this.acceptsObj[this.uploadType].indexOf(file.type) === -1) {
      //   this.$notify({
      //     message: '只能上传' + this.acceptDesc[this.uploadType],
      //     type: 'warning',
      //     offset: 50
      //   });
      //   return false;
      // }
      if (!file.size) {
        setTimeout(() => {
          this.$notify({
            message: '不能上传大小为0的文件',
            type: 'warning',
            offset: 50
          });
        }, 500);
        return false;
      }
      return this.propertyRestrictions(file);
    },
    // 文件个数限制钩子
    fileLimitFn() {
      this.$message.warning(
          `最多支持选择${this.limit}个文件`
      );
    },
    //清空文件
    clearFile() {
      this.addForm.remarks1 = '';
    },
    success(r) {
      this.$message.success('上传成功');
      //this.dialogUpload = false;

      this.addForm.remarks1 = r[0].url;
      // this.form.types = r.types;
      // this.form.name = r.fileName;
    },
    // 属性限制
    propertyRestrictions(file) {
      return new Promise((resolve, reject) => {
        if (this.uploadType === 'image') {
          this.verifyResolution(file).then((isVerifyResolution) => {
            if (!isVerifyResolution) {
              this.$message('不支持上传4K视频');
              reject();
            }
          })
          //const isVerifyResolution = await this.verifyResolution(file);
          // if (!isVerifyResolution) {
          //   this.$message('不支持上传4K视频');
          //   reject();
          // }
        }
        resolve(true);
      });
    },
    // 分辨率校验
    verifyResolution(file, maxWidth = 3840, maxHeight = 2160) {
      console.log(maxWidth)
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (reader.readyState === 2) {
            const img = new Image();
            img.src = reader.result;
            img.onload = function () {
              const width = this.width;
              const height = this.height;
              const bool = width > maxWidth || height > maxHeight;
              if (bool) {
                resolve(false);
              }
              resolve(true);
            };
          }
        };
      });
    },
    // 取消提交表单
    cancelBtn_click() {
      this.dialogFormVisible = false;
      this.$refs['addForm'].resetFields();
      this.imageUrl = ''
    },
    // 提交表单
    submitBtn_click() {
      this.addForm.contentsId = this.addForm.contentsId.toString()
      if (!this.editor.getText()) {
        this.addForm.details = this.editor.getText()
      } else {
        this.addForm.details = this.editor.getHtml()
      }

      if (this.lock) {
        return this.$message.warning('请勿频繁点击')
      }

      this.$refs.addForm.validate((valid) => {

        if (valid) {
          /* 处理视频*/
          if (!this.addForm.isLuboBoolean) {
            this.addForm.remarks1 = ''
          } else {
            if (!this.addForm.isSubscribeBoolean) {
              this.addForm.maximum = ''
              this.addForm.minimum = ''
              this.addForm.endTime = ''
            }

          }
          this.addForm.isLubo = Number(!this.addForm.isLuboBoolean);
          this.addForm.isSubscribe = Number(this.addForm.isSubscribeBoolean);
          if (!this.addForm.isSubscribeBoolean) {
            this.addForm.endTime = ''
          }
          this.lock = true
          if (this.isAdd) {
            this.addForm.deptId = this.userInfo.dept_id
            this.addForm.createUserId = this.userInfo.user_id
            this.addForm.teacherName = this.userInfo.nick_name

            addCourse(this.addForm).then(res => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.dialogFormVisible = false
                this.$refs['addForm'].resetFields()
                this.imageUrl = ''
                this.getCourseList()
              } else {
                this.$message.warning('添加失败')
              }
              this.lock = false
            })
          } else {
            updateCourse(this.addForm).then(res => {
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.dialogFormVisible = false
                this.$refs['addForm'].resetFields()
                this.imageUrl = ''
                this.getCourseList()
              } else {
                this.$message.warning('修改失败')
              }
              this.lock = false
            })
          }
        }
      })
    },
    // 选择封面
    fileChange(file) {
      this.imageUrl = file.url
    },
    // 获取教师数据
    async getCourseList() {
      this.loading = true
      this.hasEndedCourseList.courseList = []
      this.notStartedCourseList.courseList = []

      this.courseParams.createUserId = this.userInfo.user_id
      // 直播课
      let res = await getCourseList(this.courseParams).then(res => {
        return res.data
      })
      if (res) {
        this.courseTotal = res.total
        let data = res.records
        data.forEach(item => {
          switch (item.state) {
            case '0':
              item.stateVal = 4
              break
            case '1':
              switch (item.beginState) {
                case '0':
                  item.stateVal = 2
                  break
                case '1':
                  item.stateVal = 3
                  break
                case '2':
                  item.stateVal = 1
                  break
              }
              break
            case '2':
              item.stateVal = 0
              break
            case '3':
              item.stateVal = 5
              break
          }

          this.notStartedCourseList.courseList.push(item)
          this.notStartedCourseList.title = '直播'
        })
        this.getLuboList()
      }
      this.loading = false
    },
    getLuboList() {
      // 录播课
      getResourcesByUserIdPage({
        current: 1,
        size: 4,
        userId: this.userInfo.user_id
      }).then(res => {
        this.luboTotal = res.data.total
        this.hasEndedCourseList.courseList = res.data.records
        this.hasEndedCourseList.title = '录播'
        // 所有的课程
        this.courseList = this.notStartedCourseList.courseList.concat(res.data.records)
      })
    },
    processingData(data) {
      if (!data) {
        return
      }
      let teacher
      let teacherInfo
      data.forEach((item) => {
        teacher = item.introduce.split('</br>')[0]
        teacherInfo = item.introduce.split('</br>')[1]
        item.teacherName = teacher
        item.teacherInfo = teacherInfo
        item.courseType = 'course'
      })

      return data
    },
    // 查看详情
    showCourseDetails(data) {
      // 如果正在直播中的录播课，直接进入直播间
      if (data.isLubo == 0) {
        if (data.beginState == 0) {
          return this.$router.push('/liveStu/playerLubo?telecastId=' + data.id)
        }
      }

      this.pagingParams.telecastId = data.id
      this.showDetails = true
      this.courseList.forEach(item => {
        if (item.id === data.id) {
          this.courseDetails = item
        }
      })
    },
    //  点击操作按钮
    operationBtn_click({operation, id}) {
      switch (operation) {
        case 'edit':
          this.notStartedCourseList.courseList.forEach(item => {
            if (item.id == id) {
              this.dialogFormVisible = true
              this.$nextTick(() => {
                this.$refs['addForm'].resetFields()
                this.isAdd = false
                this.addForm = {
                  isSubscribeBoolean: Boolean(Number(item.isSubscribe)),//是否需要报名

                  isSubscribe: item.isSubscribe,

                  isLuboBoolean: Boolean(!Number(item.isLubo)),//是否是录播

                  isLubo: item.isLubo,
                  remarks1: item.remarks1,
                  deptId: item.deptId,
                  name: item.name,
                  series: item.series,
                  type: item.type,
                  courseStartTime: item.courseStartTime,
                  courseEndTime: item.courseEndTime,
                  endTime: item.endTime,
                  minimum: item.minimum,
                  maximum: item.maximum,
                  details: item.details,
                  id: item.id,
                  state: 3,
                  beType: Number(item.beType),
                  coverUrl: item.coverUrl,
                  beTeacher: item.beTeacher,
                  classifyId: item.classifyId,
                  contentsId: item.contentsId.split(',')
                }
                this.imageUrl = item.coverUrl
                delete this.addForm.createUserId
                setTimeout(() => {
                  this.addForm.details = item.details
                }, 0)
              })

              getResourcesContents({calssifyId: item.classifyId, type: 2}).then(res => {
                this.catalogueList = this.xxx(res.data)
              })
            }
          })
          break
        case 'submit':
          updateCourse({id, state: 0}).then(res => {
            if (res.code === 200) {
              this.$message.success('已提交审核')
              this.notStartedCourseList.courseList.forEach(item => {
                if (item.id == id) {
                  item.stateVal = 4
                }
              })
            }
          })
          break
      }
    },
    // 富文本编辑器
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    // 课程分页
    courseCurrent_click(current) {
      // console.log('当前第', current, '页')
      this.courseParams.current = current
      this.getCourseList()
    },
    // 录播分页
    luboCurrent_click(current) {
      this.luboParams.current = current
    },
    // 文件上传成功回调
    uploadSuccess(response) {
      if (response.code === 200) {
        this.addForm.coverUrl = response.data.link
      } else {
        this.$message.error('上传失败')
      }
    },
    // 文件上传前
    beforeUpload(file) {
      if (file.size > 10240000) {
        this.imageUrl = ''
        this.$message.error('图片尺寸过大')
        return false
      }
    },
    // 移除选中的封面
    handleRemove() {
      this.$confirm('是否确认移除该封面', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs['deleteCover'].style.display = 'none'
        this.imageUrl = ''
        this.addForm.coverUrl = 'http://139.155.146.142:9000/prod/upload/20220630/17664457e127299735d5f0c7835d403d.png'
      })
    },
    // 封面删除按钮显示隐藏
    overCoverImg() {
      if (this.imageUrl) this.$refs['deleteCover'].style.display = 'block'
    },
    outCoverImg() {
      if (this.imageUrl) this.$refs['deleteCover'].style.display = 'none'
    },
    // 获取目录分类
    getResourcesClassify() {
      getResourceClassify({level: 5}).then(res => {
        this.classifyList = res.data
      })
    },
    // 切换目录
    changeClassify(id) {
      this.addForm.contentsId = []
      getResourcesContents({calssifyId: id, type: 2}).then(res => {
        this.catalogueList = this.xxx(res.data)
      })
    },
    // 目录递归
    xxx(data) {
      data.forEach(item => {
        if (item.children.length === 0) {
          delete item.children
        } else {
          this.xxx(item.children)
        }
      })
      return data
    },
    // 播放录播课
    playBtn_click(courseId) {
      this.$router.push({path: '/courseDetail', query: {courseId}})
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>
<style>
.click_active {
  color: #409EFF;
}

.user_info {
  width: 100%;
  min-height: 60%;
  box-shadow: 1px 1px 20px #eee;
  background: #fff;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 14.8rem;
  height: 14.8rem;
  line-height: 14.8rem;
  text-align: center;
}

.avatar {
  width: 14.8rem;
  height: 14.8rem;
  display: block;
}

/*.el-dialog {
  !*margin-left : 25%;*!
  margin-top: 50px !important;
}*/

.el-dialog {
  transform: translateY(-50%);
  top: 50%;
  margin-top: 0 !important;
}

.float_r {
  float: right;
}

.course_detail {
  max-height: 550px;
  overflow-y: auto;
}

.course_detail img {
  max-width: 100%;
  margin: auto;
  display: block;
}

.el-dialog__body {
  padding: 10px 20px;
}


.el-input-number, .el-date-editor.el-input, .el-date-editor.el-input__inner, .el-select, .el-cascader {
  width: 25rem;
}


.course-box {
  width: 28.5rem;
  margin-right: 2rem;
  position: relative;
  transition: all .5s;
}

.course-box:nth-child(4n) {
  margin-right: 0;
}

.course-box:hover {
  box-shadow: 0 0 1rem .2rem #e0e0e0;
  transform: translateY(-.8rem);
  transition: all .5s;
}

.live_tag {
  position: absolute;
  right: 10px;
}

/* 上传组件样式*/
.mater-upload-container {

>>> .simple-upload-container {
  border: none;
  max-height: 500px;
}

}
</style>
